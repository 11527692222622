<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav :selectedLanguage="selectedLanguage" page="blog2" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Como recuperar dados do GB WhatsApp?
              </h1>

              <!-- <div class="write-by">
                {{ $global.formatTimestamp(new Date()) }} Por Amir Khan
              </div> -->

              <p class="writter-content">
                Muitos usuários preferem o <router-link :to="{name:`${selectedLanguage}-gb`}" class="jump-url" >GB WhatsApp</router-link> ao aplicativo oficial por causa de seus recursos avançados, especialmente para conversas relacionadas ao trabalho ou negócios. Isso significa que os dados armazenados no GB WhatsApp são mais valiosos e insubstituíveis.
                <br>
                Se você acidentalmente excluiu ou perdeu algum dado, não entre em pânico. Existem várias maneiras de recuperar os dados perdidos do GB WhatsApp, e este guia apresentará os métodos de recuperação mais eficazes.
              </p>
              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="recuperar dados do GB WhatsApp"
                    src="../assets/blog-2.webp"></picture>
              </div>

              <h2 class="intro-title blog">
                Recuperando mensagens excluídas no GB WhatsApp
              </h2>

              <p class="writter-content">
                Se você excluiu mensagens acidentalmente ou as perdeu devido a um problema técnico, há passos que você pode seguir para recuperá-las. O processo depende de ter ou não um backup dos dados.
              </p>

              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="backup e restauração"
                    src="../assets/backup.jpg"></picture>
              </div>

              <h3 class="intro-title blog">
                Método 1: Para usuários com backup
              </h3>

              <p class="writter-content">
                Se você já fez backup das suas conversas no GB WhatsApp, recuperá-las é bem fácil. Veja como:<br>
                1. Certifique-se de que você tem um backup das suas conversas (de preferência armazenado no Google Drive).<br>
                2. Baixe a pasta de backup do Google Drive para o seu celular Android.<br>
                3. Pressione longamente a pasta baixada e selecione a opção Copiar.<br>
                4. Cole a pasta copiada no diretório raiz ou em uma pasta local do seu celular.<br>
                5. Abra o aplicativo GB WhatsApp e verifique seu número de telefone.<br>
                6. Após a verificação, você verá uma mensagem informando que há um backup disponível. Selecione Restaurar.<br>
                Suas conversas devem ser recuperadas, permitindo que você continue suas interações sem interrupções.
              </p>

              <h3 class="intro-title blog">
                Método 2: Para usuários sem backup
              </h3>

              <p class="writter-content">
                Se você não fez backup dos dados, a recuperação pode ser um pouco mais desafiadora. Nesse caso, será necessário recorrer a ferramentas de recuperação de dados de terceiros para tentar recuperar as mensagens perdidas. Uma rápida pesquisa no Google fornecerá várias opções de ferramentas projetadas especificamente para recuperar dados do WhatsApp.
              </p>

              <h2 class="intro-title blog">
                Localizando arquivos de backup do GB WhatsApp
              </h2>

              <p class="writter-content">
                Se você não tem certeza de onde seus backups do GB WhatsApp estão armazenados, pode encontrá-los seguindo estas etapas: <br>
                1. Acesse o <strong>Armazenamento</strong> do seu dispositivo.<br>
                2. Encontre e toque na pasta chamada <strong>GB WhatsApp</strong>.<br>
                3. Dentro do GB WhatsApp, abra a pasta <strong>Database</strong>.<br>
                4. Aqui, você verá uma lista de arquivos de backup. Selecione o arquivo mais recente.<br>
                5. Renomeie o arquivo de backup e copie-o.<br>
                6. Cole o arquivo de backup na pasta <strong>Database</strong> padrão do WhatsApp.
              </p>

              <h2 class="intro-title blog">
                Fazendo backup do GB WhatsApp no Google Drive
              </h2>

              <p class="writter-content">
                Para evitar a perda de dados no futuro, é importante fazer backup das suas conversas no GB WhatsApp regularmente. Siga estas etapas para fazer backup dos seus dados no Google Drive: <br>
                1. Abra o <strong>GB WhatsApp</strong> no seu telefone.<br>
                2. Toque no <strong>Menu</strong> (três pontos) e vá para <strong>Configurações</strong>.<br>
                3. Acesse <strong>Conversas > Backup de Conversas</strong>.<br>
                4. Selecione a opção <strong>Fazer backup no Google Drive</strong> e escolha a conta do Google que deseja usar para o backup.<br>
                Isso garantirá que suas conversas sejam armazenadas com segurança na nuvem e possam ser facilmente recuperadas em caso de perda de dados.
              </p>

              <h2 class="intro-title blog">
                Recuperando conversas ocultas no GB WhatsApp
              </h2>

              <p class="writter-content">
                O GB WhatsApp permite ocultar conversas por questões de privacidade, mas se você deseja recuperar ou desocultar essas conversas ocultas, o processo é simples: <br>
                1. Abra o <strong>GB WhatsApp</strong>.<br>
                2. Pressione longamente o contato cuja conversa oculta você deseja recuperar.<br>
                3. Toque nos três pontos no canto superior direito.<br>
                4. No menu suspenso, selecione <strong>Desocultar Conversa</strong>.<br>
                Seguindo estas etapas, a conversa oculta será revelada novamente, permitindo que você acesse quando quiser.
              </p>

              <h2 class="intro-title blog">
                Download do GB WhatsApp e Atualização do GB WhatsApp
              </h2>

              <p class="writter-content">
                Para baixar o GB WhatsApp, clique no botão abaixo:
              </p>

              <div
                class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
                <div class="wp-block-button">
                  <a id="dl" class="wp-block-button__link wp-element-button" target="_blank"
                    rel="dl noopener" @click="gotodownload()">Página de Download</a>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Política de Privacidade</a>
          </div>
          <div class="copyright-bar">
            2022 © Todos os direitos reservados <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      <a target="_blank" class="bottom-dl-btn">Página de Download</a>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'pt',
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/pt/downloadpage' + params;
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
